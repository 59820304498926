import ApiService from './api.service'

export default class HospitalService {

	async getHospitals(_params) {
        const response = await ApiService.get('api/hospitals', _params)
        return response.data
    }

	async getHospital(id) {
        const response = await ApiService.get('api/hospitals/'+id)
        return response.data
    }

	async getHospitalName(id) {
        const response = await ApiService.get('api/hospitals/getname/'+id)
        return response.data
    }

	async getHospitalsForSelect(_params) {
        const response = await ApiService.get('api/hospitals/select', _params)
        return response.data.data
    }
    
	async newHospital(hospital) {
        const response = await ApiService.post('api/hospitals', hospital)
        return response.data.id
    }

	async updateHospital(hospital) {
        const response = await ApiService.put('api/hospitals/'+hospital.id, hospital)
        return response.data.id
    }

	async deleteHospital(id) {
        const response = await ApiService.delete('api/hospitals/'+id)
        return response
    }

	async newDept(dept) {
        const response = await ApiService.post('api/depts', dept)
        return response.data.id
    }

	async updateDept(dept) {
        const response = await ApiService.put('api/depts/'+dept.id, dept)
        return response.data.id
    }

	async deleteDept(id) {
        const response = await ApiService.delete('api/depts/'+id)
        return response
    }

	async newDoc(doc) {
        const response = await ApiService.post('api/depts/docattach', doc)
        return response.data.id
    }

	async deleteDoc(doc) {
        const response = await ApiService.post('api/depts/docdetach', doc)
        return response
    }

}
