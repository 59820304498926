<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>

                <!-- Táblázat -->

				<DataTable ref="dt" :value="hospitals" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="5" :filters="filters"
                            sortField="hospname" :sortOrder="1" :loading="loading" @filter="onFilter($event)" @row-click="expandRow" :rowClass="rowClass"
                            :lazy="true" :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Kórházak</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="hospname" header="Megnevezés" sortable></Column>
					<Column field="city" header="Város" sortable></Column>
					<Column field="postcode" header="IRSZ" sortable></Column>
					<Column field="address" header="Cím" sortable></Column>
					<Column field="depts_number" header="Osztályok száma" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editHospital(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDeleteHospital(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                            </div>
                            <DataTable ref="dt_dept" :value="slotProps.data.depts" v-model:expandedRows="expandedDeptRows" dataKey="id"
                                        sortField="deptname" :sortOrder="1" @row-click="expandDeptRow" :rowClass="rowClass"
                                        class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows l-datatable-nar-rows">
                                <template #header>
                                    <div class="table-header">
                                        <div>
                                            <Button label="Új" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="openDeptNew(slotProps.data.id)" />
                                            <h5 class="p-m-0 p-d-inline-block">Osztályok</h5>
                                        </div>
                                    </div>
                                </template>

                                <Column :expander="true" headerStyle="width: 3rem" />
                                <Column field="deptname" header="Megnevezés" sortable></Column>
                                <Column field="doctors_number" header="Orvosok száma" sortable></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2" @click="editDept(slotProps.data.id)" />
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDeleteDept(slotProps.data)" />
                                    </template>
                                </Column>
                                <template #expansion="slotProps">
                                    <Card>
                                    <template #content>
                                        <DataTable ref="dt_doc" :value="slotProps.data.doctors" v-model:expandedRows="expandedDocRows" dataKey="id"
                                                    sortField="fullname" :sortOrder="1" @row-click="expandDocRow" :rowClass="rowClass"
                                                    class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows l-datatable-nar-rows">
                                            <template #header>
                                                <div class="table-header">
                                                    <div>
                                                        <Button label="Új" icon="pi pi-plus" class="p-button-info p-mr-2" @click="openDocNew(slotProps.data.id)" />
                                                        <h5 class="p-m-0 p-d-inline-block">Orvosok</h5>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column :expander="true" headerStyle="width: 3rem" />
                                            <Column field="fullname" header="Név" sortable></Column>
                                            <Column field="prof2" header="Szakterület" sortable></Column>
                                            <Column>
                                                <template #body="slotProps">
                                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDeleteDoc(slotProps.data)" />
                                                </template>
                                            </Column>
                                            <template #expansion="slotProps">
                                                <Card>
                                                <template #content>
                                                    <div class="p-grid">
                                                        <div class="p-col" style="margin-left: 2rem">
                                                            <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                                            <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                                        </div>
                                                    </div>
                                                </template>                            
                                                </Card>
                                            </template>				
                                        </DataTable>
                                    </template>                            
                                    </Card>
                                </template>				
                            </DataTable>
                        </template>                            
                        </Card>
                    </template>				
                </DataTable>


                <!-- Szerkesztő ablak - Hospital -->

				<Dialog id="dialog" v-model:visible="hospitalDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Kórház" :modal="true" class="p-fluid"
                    @show="dialogShow" @keydown.enter.ctrl="saveHospital()" @keyup.esc="hideDialog()">
					<span class="p-field p-float-label" style="margin-top: 1rem">
						<InputText id="hospname" ref="firstEditField" v-model.trim.lazy="hospital.hospname" :v="v$.hospital.hospname" @blur="v$.hospital.hospname.$touch"
                            required="true" :class="{'p-invalid': v$.hospital.hospname.$invalid}" autocomplete="new-hosp-hospname"
                        />
						<label for="hospname">Megnevezés</label>
						<small class="p-invalid" v-if="vCheckRequire('hospname')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="city" v-model.trim.lazy="hospital.city" :v="v$.hospital.city" @blur="v$.hospital.city.$touch"
                            required="true" :class="{'p-invalid': v$.hospital.city.$invalid}" autocomplete="new-hosp-city"
                        />
						<label for="city">Város</label>
						<small class="p-invalid" v-if="vCheckRequire('city')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="postcode" v-model.trim.lazy="hospital.postcode" :v="v$.hospital.postcode" @blur="v$.hospital.postcode.$touch"
                            required="true" :class="{'p-invalid': v$.hospital.postcode.$invalid}" autocomplete="new-hosp-postcode"
                        />
						<label for="postcode">IRSZ</label>
						<small class="p-invalid" v-if="vCheckRequire('postcode')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="address" v-model.trim.lazy="hospital.address" :v="v$.hospital.address" @blur="v$.hospital.address.$touch"
                            required="true" :class="{'p-invalid': v$.hospital.address.$invalid}" autocomplete="new-hosp-address"
                        />
						<label for="address">Cím</label>
						<small class="p-invalid" v-if="vCheckRequire('address')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea id="description" v-model="hospital.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveHospital"/>
					</template>
				</Dialog>

                <!-- Szerkesztő ablak - Dept -->

				<Dialog id="deptDialog" v-model:visible="deptDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Kórház osztálya" :modal="true" class="p-fluid"
                    @show="dialogDeptShow" @keydown.enter.ctrl="saveDept()" @keyup.esc="hideDeptDialog()">
					<span class="p-field p-float-label" style="margin-top: 1rem">
						<InputText v-model.trim.lazy="dept.deptname" ref="firstDeptEditField" :v="v$.dept.deptname" @blur="v$.dept.deptname.$touch"
                            required="true" :class="{'p-invalid': v$.dept.deptname.$invalid}" autocomplete="new-dept-deptname"
                        />
						<label>Megnevezés</label>
						<small class="p-invalid" v-if="vCheckDeptRequire('deptname')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<Textarea v-model="dept.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label>Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDeptDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveDept"/>
					</template>
				</Dialog>

                <!-- Szerkesztő ablak - Doc -->

				<Dialog id="docDialog" v-model:visible="docDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Osztály orvosa" :modal="true" class="p-fluid"
                    @show="dialogDocShow" @keydown.enter.ctrl="saveDoc()" @keyup.esc="hideDocDialog()">
					<span class="p-field p-float-label" style="margin-top: 1rem">
                        <AutoComplete ref="firstDocEditField" v-model="doc.doctor" :suggestions="filteredDoctors" @complete="searchDoctor($event)" field="fullname"
                            :minLength="2" :class="{'p-invalid': v$.doc.doctor.$invalid}" autocomplete="new-doc-doctor" placeholder="Keresés 2 karaktertől..."
                            forceSelection required="true" :v="v$.doc.doctor" @item-select="v$.doc.doctor.$touch; doc.prof2=doc.doctor.prof2;">
                            <template #item="slotProps">
                                <div>{{slotProps.item.fullname}} - {{slotProps.item.prof2}}</div>
                            </template>
                        </AutoComplete>
						<label style="top: 1px; font-size: 12px;">Orvos</label>
						<small class="p-invalid" v-if="vCheckDocRequire('doctor')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText v-model="doc.prof2" disabled/>
						<label>Szakterület</label>
					</span>
					<span class="p-field p-float-label">
						<Textarea v-model="doc.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label>Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDocDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveDoc"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteHospitalDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="hospital">Biztosan töröljük az kórházat: <b>{{hospital.hospname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteHospitalDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteHospital"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDeptDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="dept">Biztosan töröljük az osztályt: <b>{{dept.deptname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteDeptDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteDept"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDocDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="doc">Eltávolítsuk az osztályról az orvost: <b>{{doc.fullname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteDocDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteDoc"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import HospitalService from '@/service/backend/hospital.service'
import DoctorService from '@/service/backend/doctor.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
		return {
			hospitals: null,
			hospitalDialog: false,
			deleteHospitalDialog: false,
			hospital: {},
			expandedRows: [],
			deptDialog: false,
			deleteDeptDialog: false,
            dept: {},
            expandedDeptRows: [],
			docDialog: false,
			deleteDocDialog: false,
            doc: { },
            filteredDoctors: null,
            expandedDocRows: [],
			filters: {},
            deptFilters: {},
            submitted: false,
            searchGlobal: "",
            totalRecords: 0,
            lazyParams: {},
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

		}
	},
    validations: {
        hospital: {
            hospname: { required },
            address: { required }, 
            postcode: { required }, 
            city: { required }
        },
        dept: {
            deptname: { required }
        },
        doc: {
            doctor: { required }
        }
    },
    hospitalService: null,
    doctorService: null,
	created() {
		this.hospitalService = new HospitalService()
		this.doctorService = new DoctorService()
    },
	mounted() {
        this.lazyParams = {
            first: 0,
            page: 1,
            rows: this.$refs.dt.rows,
            sortField: this.$refs.dt.sortField,
            sortOrder: 1,
            filters: null
        };

        this.loadLazyData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadLazyData() {
            this.loading = true;

            delete this.lazyParams.originalEvent
            delete this.lazyParams.pageCount
            this.lazyParams.filters = this.filters.global

            this.hospitalService.getHospitals(this.lazyParams)
            .then( result => {
                result.data.forEach(e => { 
                    e.depts_number = e.depts.length 
                    e.depts.forEach(d => { 
                        d.doctors_number = d.doctors.length 
                    })
                })
                this.hospitals = result.data
                this.totalRecords = result.totalRecords
                this.loading = false
            })
        },
        onPage(event) {
            this.lazyParams = event
            this.lazyParams.page++
            this.loadLazyData()
        },
        onSort(event) {
            this.onPage(event)
        },
        onFilter() {
            this.loading = true
            this.lazyParams.page = 1
            this.loadLazyData()
            this.loadingSearch = false
        },
        rowClass(data) {
            return (data.expanded) ? 'l-expanded' : ''
        },
        vCheckRequire(field){
            return this.v$.hospital[field].$dirty && !this.v$.hospital[field].required
        },
        vCheckDeptRequire(field){
            return this.v$.dept[field].$dirty && !this.v$.dept[field].required
        },
        vCheckDocRequire(field){
            return this.v$.doc[field].$dirty && !this.v$.doc[field].required
        },
        expandRow(e) {
            if(this.expandedRows.length !== 0)
                this.expandedRows[0].expanded = false
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.hospitals.filter(p => p.id == e.data.id);
                this.expandedRows[0].expanded = true
            }else{
                this.expandedRows = []
            }
            if(this.expandedDeptRows.length !== 0)
                this.expandedDeptRows[0].expanded = false
            this.expandedDeptRows = []
            if(this.expandedDocRows.length !== 0)
                this.expandedDocRows[0].expanded = false
            this.expandedDocRows = []
        },
        expandDeptRow(e) {
            if(this.expandedDeptRows.length !== 0)
                this.expandedDeptRows[0].expanded = false
            if(this.expandedDeptRows.length === 0 || this.expandedDeptRows[0].id !== e.data.id ){
                this.expandedDeptRows = this.hospitals.filter(p => p.id == this.expandedRows[0].id)[0]
                                            .depts.filter(p => p.id == e.data.id);
                this.expandedDeptRows[0].expanded = true
            }else{
                this.expandedDeptRows = []
            }
            if(this.expandedDocRows.length !== 0)
                this.expandedDocRows[0].expanded = false
            this.expandedDocRows = []
        },
        expandDocRow(e) {
            if(this.expandedDocRows.length !== 0)
                this.expandedDocRows[0].expanded = false
            if(this.expandedDocRows.length === 0 || this.expandedDocRows[0].id !== e.data.id ){
                this.expandedDocRows = this.hospitals.filter(p => p.id == this.expandedRows[0].id)[0]
                                            .depts.filter(p => p.id == this.expandedDeptRows[0].id)[0]
                                            .doctors.filter(p => p.id == e.data.id);
                this.expandedDocRows[0].expanded = true
            }else{
                this.expandedDocRows = []
            }
        },
        openDialog(){
            this.hospitalDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
        dialogShow() {
            this.$refs.firstEditField.$el.focus()
        },
		openNew() {
            this.hospital = {}
            this.openDialog()
		},
		hideDialog() {
			this.hospitalDialog = false
            this.submitted = false
		},
		async saveHospital() {

            this.submitted = true;
            this.v$.hospital.$touch()

            if (!this.v$.hospital.$invalid) {

                    try{
                        if (this.hospital.id) {
                            this.hospital.id = await this.hospitalService.updateHospital(this.hospital)
                            this.hospitals[this.findIndexById(this.hospital.id)] = this.hospital
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.hospital.id = await this.hospitalService.newHospital(this.hospital)
                            this.hospital.depts = []
                            if(this.hospitals.length == this.$refs.dt.rows){
                                this.hospitals.pop()
                            }
                            this.hospitals.unshift(this.hospital)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch{
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editHospital(id) {
            this.hospital = JSON.parse(JSON.stringify(this.hospitals.find( d => d.id === id)));
            this.openDialog()
		},
		confirmDeleteHospital(hospital) {
			this.hospital = hospital
			this.deleteHospitalDialog = true
		},
		async deleteHospital() {
            this.submitted = true
            try{
                await this.hospitalService.deleteHospital(this.hospital.id)
                this.hospitals = this.hospitals.filter(val => val.id !== this.hospital.id)
                this.deleteHospitalDialog = false
                this.hospital = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Kórház törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.hospitals.length; i++) {
				if (this.hospitals[i].id === id) {
					index = i
					break
				}
			}
			return index;
		},
		findDeptIndexById(hospitalIndex, id) {
			let index = -1
            const hosp = this.hospitals[hospitalIndex]
            const deptslength = hosp.depts.length
			for (let i = 0; i < deptslength; i++) {
				if (hosp.depts[i].id === id) {
					index = i
					break
				}
			}
			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
        openDeptDialog(){
            this.deptDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
        dialogDeptShow() {
            this.$refs.firstDeptEditField.$el.focus()
        },
		openDeptNew(hospitalid) {
            this.dept = { hospitalid }
            this.openDeptDialog()
		},
		hideDeptDialog() {
			this.deptDialog = false
            this.submitted = false
		},
		async saveDept() {

            this.submitted = true;
            this.v$.dept.$touch()

            if (!this.v$.dept.$invalid) {
                    try{
                        const hospitalIndex = this.findIndexById(this.dept.hospitalid)
                        if (this.dept.id) {
                            this.dept.id = await this.hospitalService.updateDept(this.dept)
                            const deptIndex = this.findDeptIndexById(hospitalIndex, this.dept.id)
                            this.hospitals[hospitalIndex].depts[deptIndex] = this.dept
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.dept.id = await this.hospitalService.newDept(this.dept)
                            this.dept.doctors_number = 0
                            this.dept.doctors = []
                            this.hospitals[hospitalIndex].depts.unshift(this.dept)
                            this.hospitals[hospitalIndex].depts_number++
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.hideDeptDialog()
            
            }

            this.submitted = false

		},
		editDept(id) {
            this.dept = JSON.parse(JSON.stringify(this.expandedRows[0].depts.find( d => d.id === id)));
            this.openDeptDialog()
		},
		confirmDeleteDept(dept) {
			this.dept = dept
			this.deleteDeptDialog = true
		},
		async deleteDept() {
            this.submitted = true
            try{
                await this.hospitalService.deleteDept(this.dept.id)
                const hIndex = this.findIndexById(this.dept.hospitalid)
                this.hospitals[hIndex].depts = this.hospitals[hIndex].depts.filter(val => val.id !== this.dept.id)
                this.hospitals[hIndex].depts_number--
                this.deleteDeptDialog = false
                this.dept = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Osztály törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
        openDocDialog(){
            this.docDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
        dialogDocShow() {
            this.$refs.firstDocEditField.$el.focus()
        },
		openDocNew(hospdeptid) {
            this.doc = { hospdeptid }
            this.openDocDialog()
		},
        searchDoctor(event) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.doctorService.getDoctorsForSelect({filters: event.query })
                .then( result => {
                    this.filteredDoctors = result
                })
            }, 600)
        },
		hideDocDialog() {
			this.docDialog = false
            this.submitted = false
		},
		async saveDoc() {

            this.submitted = true;
            this.v$.doc.$touch()

            if (!this.v$.doc.$invalid) {
                    try{
                        this.doc.id = await this.hospitalService.newDoc({
                                                doctorid: this.doc.doctor.id, 
                                                hospdeptid: this.doc.hospdeptid,
                                                note: this.doc.note}
                                            )
                        const hospitalIndex = this.findIndexById(this.expandedRows[0].id) // FIXME, ha több lehet expandolva, akkor nem jó így... 
                        const deptIndex = this.findDeptIndexById(hospitalIndex, this.doc.hospdeptid)
                        this.doc.id = this.doc.doctor.id
                        this.doc.pivot = {}
                        this.doc.pivot.doctorid = this.doc.doctor.id
                        this.doc.pivot.hospdeptid = this.doc.hospdeptid
                        this.doc.fullname = this.doc.doctor.fullname
                        this.doc.prof2 = this.doc.doctor.prof2
                        
                        this.hospitals[hospitalIndex].depts[deptIndex].doctors.push(this.doc) // TODO kellene a doki többi adata is a szerverről... ja de a kiválasztáskor úgyis jön
                        this.hospitals[hospitalIndex].depts[deptIndex].doctors_number++

                        this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.hideDocDialog()
            
            }

            this.submitted = false

		},
		confirmDeleteDoc(doc) {
			this.doc = doc
			this.deleteDocDialog = true
		},
		async deleteDoc() {
            this.submitted = true
            try{
                await this.hospitalService.deleteDoc(this.doc.pivot)
                const hIndex = this.findIndexById(this.expandedRows[0].id) // FIXME, ha több lehet expandolva, akkor nem jó így... 
                const dIndex = this.findDeptIndexById(hIndex, this.doc.pivot.hospdeptid)
                this.hospitals[hIndex].depts[dIndex].doctors = this.hospitals[hIndex].depts[dIndex].doctors.filter(val => val.id !== this.doc.id)
                this.hospitals[hIndex].depts[dIndex].doctors_number--
                this.deleteDocDialog = false
                this.doc = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Orvos leválasztva!', life: 3000})
            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { 
                    this.filters['global'] = val
                    this.onFilter(); 
                }, 20)
            }, 600)
        }
    },
    computed: {
/*        ...mapGetters({
            codesHospitalClass: 'codeStore/hospitalClass'
        })  */
    }
}
</script>
<style lang="postcss">
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
.l-datatable-nar-rows td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.l-expanded {
    background-color: #607d8b33 !important;
}

</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.hospital-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .hospital-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.hospital-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button-success {
			margin-bottom: .25rem;
		}
    }

}

</style>
